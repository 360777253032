import React from 'react';

import './App.css';

import * as api from './api';

import {
  AppBar,
  Container,
  createMuiTheme,
  CssBaseline,
  Divider,
  Drawer,
  Grid,
  IconButton,
  makeStyles,
  Paper,
  ThemeProvider,
  Toolbar,
  Typography,
} from '@material-ui/core';

import clsx from 'clsx';

import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { PageID, pages, SideMenu } from './SideMenu';
import { initialPlanGroupping } from './initialGroupping';

import { DataEntry } from './data';
import ChartPage from './pages/ChartPage';

const drawerWidth = 250;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(7),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflowX: 'hidden',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 'calc(100vh - 125px)',
  },
}));

function App() {
  const classes = useStyles();

  const [crawlDates, setCrawlDates] = React.useState<string[]>([]);

  const [globalData, setGlobalData] = React.useState<{ [crawlDate: string]: DataEntry[] }>({});
  const [planGroupping, setPlanGroupping] = React.useState(initialPlanGroupping);
  const [currentCrawlDate, setCurrentCrawlDate] = React.useState<string | undefined>();

  const [deviceMappings, setDeviceMappings] = React.useState<any[]>([]);

  React.useEffect(() => {
    api.getDevicePrices().then((res) => {
      setGlobalData(res.data);
      setCrawlDates(res.crawlDates);
      setCurrentCrawlDate(res.crawlDates[0]);
    });

    api.getDeviceMappings().then((res) => {
      setDeviceMappings(res);
    });
  }, []);

  const [currentPage, setCurrentPage] = React.useState<PageID>(Object.keys(pages)[0] as PageID);

  const [open, setOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const selectCurrentCrawlDate = (crawlDate: string) => {
    if (!globalData[crawlDate]) {
      api.getDevicePrices(crawlDate).then((res) => {
        setGlobalData({ ...globalData, ...res.data });
      });
    }

    setCurrentCrawlDate(crawlDate);
  };

  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  const theme = createMuiTheme({
    palette: {
      type: 'light', // 'dark'
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className={classes.root}>
        <CssBaseline />
        <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
          <Toolbar className={classes.toolbar}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
            >
              <MenuIcon />
            </IconButton>
            <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
              Vivacom
            </Typography>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          classes={{
            paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
          }}
          open={open}
        >
          <div className={classes.toolbarIcon}>
            <IconButton onClick={handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <Divider />
          <SideMenu currentPage={currentPage} onPageChange={setCurrentPage} />
        </Drawer>
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <Container maxWidth={false} className={classes.container}>
            <Grid container spacing={0}>
              {/* Chart */}
              <Grid item xs={12} md={12} lg={12}>
                <Paper className={fixedHeightPaper}>
                  {Object.entries(pages).map(([pageName, val]) => {
                    if (currentPage === pageName && pageName === 'info') {
                      return (val as any).customPage;
                    } else {
                      if (currentPage === pageName) {
                        return (
                          <ChartPage
                            chart={(val as any).chart}
                            chart2={(val as any).chart2}
                            extendChart={(val as any).extend}
                            name={pageName}
                          />
                        );
                      } else {
                        return null;
                      }
                    }
                  })}
                </Paper>
              </Grid>
            </Grid>
          </Container>
        </main>
      </div>
    </ThemeProvider>
  );
}

export default App;
