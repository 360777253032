const BACKEND =
  process.env.NODE_ENV === 'development' ? 'http://localhost:5000' : 'https://vivacom-backend.herokuapp.com';

export function getDevicePrices(crawlDate?: string) {
  return fetch(BACKEND + '/devicePrices' + (crawlDate ? '?crawlDate=' + crawlDate : '')).then((f) => f.json());
}

export function getDeviceNames(): Promise<string[]> {
  return fetch(BACKEND + '/deviceNames').then((f) => f.json());
}

export function getDeviceChannels(deviceName: string): Promise<{ channels: any[] }> {
  return fetch(BACKEND + '/deviceChannels?deviceName=' + encodeURIComponent(deviceName)).then((f) => f.json());
}

export function getDeviceTimeseries(
  deviceName: string,
  priceType: '24cash' | '24lease' | 'retail',
  planName: string,
): Promise<any[]> {
  return fetch(
    BACKEND +
      '/deviceTimeseries?deviceName=' +
      encodeURIComponent(deviceName) +
      '&priceType=' +
      priceType +
      '&planName=' +
      encodeURIComponent(planName),
  ).then((f) => f.json());
}

export function getLatestPlanPrices() {
  return fetch(BACKEND + '/latestPlanPrices').then((f) => f.json());
}

export function getDeviceMappings() {
  return fetch(BACKEND + '/deviceMappings').then((f) => f.json());
}
