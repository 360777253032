import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import InfoIcon from '@material-ui/icons/Info';

import DevicesIcon from '@material-ui/icons/Devices';
import DynamicFeedIcon from '@material-ui/icons/DynamicFeed';
import TableChartIcon from '@material-ui/icons/TableChart';
import TimelineIcon from '@material-ui/icons/Timeline';

import SimCardIcon from '@material-ui/icons/SimCard';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';

import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
import { List } from '@material-ui/core';
import InfoPage from './pages/InfoPage';

export const pages = {
  info: {
    title: 'Info',
    icon: <InfoIcon />,
    customPage: <InfoPage />,
  },

  newsfeed: {
    title: 'Newsfeed',
    icon: <DynamicFeedIcon />,
    chart: '/public/report/ba5eaa878ee74daa9dcfda9e6ddf095b',
    chart2: '',
  },

  devicePriceMatrix: {
    title: 'Device Price Matrix',
    icon: <TableChartIcon />,
    chart: '/public/report/0426e7471d8c497a8631691a5446c0d0',
    chart2: '',
    extend: true,
  },

  deviceComparison: {
    title: 'Competitor Device Overlap',
    icon: <DevicesIcon />,
    chart: '/public/report/fb9e546d95fb476fbd0782f8dc985d2f',
    chart2: '/public/report/6803ce1eda8f411e86e6d154d19ef6e0',
  },

  pricingComparison: {
    title: 'Competitor Pricing Comparison',
    icon: <CompareArrowsIcon />,
    chart: '/public/report/d4f0ebcbc3d049f9bbcd5330bf54b656',
    chart2: '/public/report/76907a74286e4e7daea06fce389673c3',
    extend: true,
  },

  devicePricingHistory: {
    title: 'Device Pricing History',
    icon: <TimelineIcon />,
    chart: '/public/report/b34d0157de7f4c66b9f8a6c27c6cf2e4',
    chart2: '',
    extend: true,
  },

  planGroupAndPrices: {
    title: 'Plan Groups and Prices',
    icon: <SimCardIcon />,
    chart: '/public/report/e28b9298e6c1458c93d47cc78f08729e',
    chart2: '',
  },

  deviceIdentityMapping: {
    title: 'Device Identity Mapping',
    icon: <CompareArrowsIcon />,
    chart: '/public/report/02ad96d4c99a4845a8ebd79f93ee9604',
    chart2: '',
  },

  planIdentityMapping: {
    title: 'Plan Identity Mapping',
    icon: <CompareArrowsIcon />,
    chart: '/public/report/b8eed3f877b1481c88c66373de8d1a95',
    chart2: '',
  },
};

export type PageID = keyof typeof pages;

export const SideMenu: React.FunctionComponent<{
  currentPage: PageID;
  onPageChange: (newPage: PageID) => void;
}> = ({ currentPage, onPageChange }) => {
  return (
    <List>
      {Object.entries(pages).map(([pageName, val]) => (
        <ListItem button selected={currentPage === pageName} onClick={() => onPageChange(pageName as PageID)}>
          <ListItemIcon>{val.icon}</ListItemIcon>
          <ListItemText primary={val.title} style={{ fontSize: '0.8rem' }} />
        </ListItem>
      ))}

      <ListItem button disabled>
        <ListItemIcon>
          <PlayCircleOutlineIcon />
        </ListItemIcon>
        <ListItemText primary="Trigger a Crawl" style={{ fontSize: '0.8rem' }} />
      </ListItem>
    </List>
  );
};
