import { Grid, Typography } from '@material-ui/core';

import React from 'react';

const InfoPage: React.FC = () => {
  return (
    <>
      <Grid container direction="column">
        <Grid item>
          <Typography variant="h5" component="h5">
            Proof of Concept Info
          </Typography>
        </Grid>

        <Grid item style={{ marginTop: '30px' }}>
          <Typography variant="body1">
            The goal of this proof of concept is to build a representative “vertical slice” that illustrates how the end
            product will work.
          </Typography>
        </Grid>

        <Grid item style={{ marginTop: '10px' }}>
          <Typography variant="body1">
            <ul>
              <li>
                <b>Proof of concept scope</b>
                <ul>
                  <li>3 websites only - Vivacom, A1, Telenor</li>
                  <li>Smartphone data only</li>
                  <li>Voice plans only</li>
                  <li>Date range: 2021-03-28 to 2021-04-11</li>
                </ul>
              </li>

              <br />
              <li>
                <b>Available dashboards</b>
                <ul>
                  <li>Plan Grouping</li>
                  <li>Device Pricing History</li>
                  <li>Device Matrix History</li>
                  <br />

                  <li>Device Identity Mapping</li>
                  <li>Plan Identity Mapping</li>
                  <br />

                  <li>Competitor Pricing Comparison</li>
                  <li>Competitor Device Overlap</li>
                  <li>Newsfeed</li>
                </ul>
              </li>

              <br />

              <li>
                <b>Disabled features</b>
                <ul>
                  <li>Edit plan identity mapping</li>
                  <li>Edit device identity mapping</li>
                  <li>Trigger a custom crawl</li>
                  <li>Edit plan groups</li>
                </ul>
              </li>
            </ul>
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default InfoPage;
