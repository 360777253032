export const initialPlanGroupping = [
  [
    { channel: 'Vivacom', planName: 'Smart S+', price: 13.99 },
    { channel: 'A1', planName: 'A1 ONE S', price: 13.99 },
    { channel: 'A1', planName: 'A1 ONE 14,99', price: 14.99 },
    { channel: 'Telenor', planName: 'Тотал+ 13,99', price: 13.99 },
  ],
  [
    { channel: 'Vivacom', planName: 'Smart M', price: 16.99 },
    { channel: 'A1', planName: 'A1 ONE M', price: 17.99 },
    { channel: 'A1', planName: 'A1 ONE 17,99', price: 17.99 },
    { channel: 'Telenor', planName: 'Тотал+ 17,99', price: 17.99 },
    { channel: 'Telenor', planName: 'Тотал+ 18,99', price: 18.99 },
  ],
  [
    { channel: 'Vivacom', planName: 'Smart M+', price: 22.99 },
    { channel: 'A1', planName: 'A1 ONE L', price: 22.99 },
    { channel: 'A1', planName: 'A1 ONE 21,99', price: 21.99 },
    { channel: 'Telenor', planName: 'Тотал+ 23,99', price: 23.99 },
    { channel: 'Telenor', planName: 'Тотал+ 24,99', price: 24.99 },
  ],
  [
    { channel: 'Vivacom', planName: 'Smart L', price: 26.99 },
    { channel: 'A1', planName: 'A1 ONE XL', price: 26.99 },
    { channel: 'A1', planName: 'A1 ONE 27,99', price: 27.99 },
    { channel: 'Telenor', planName: 'Тотал+ 26,99', price: 26.99 },
    { channel: 'Telenor', planName: 'Тотал+ 27,99', price: 27.99 },
  ],
  [
    { channel: 'Vivacom', planName: 'Smart L+', price: 30.99 },
    { channel: 'A1', planName: 'A1 ONE Unlimited 2XL', price: 30.99 },
    { channel: 'A1', planName: 'A1 ONE 31,99', price: 31.99 },
    { channel: 'Telenor', planName: 'Тотал+ 30,99', price: 30.99 },
    { channel: 'Telenor', planName: 'Тотал+ 31.99', price: 31.99 },
  ],
  [
    { channel: 'Vivacom', planName: 'Unlimited 50', price: 34.99 },
    { channel: 'A1', planName: 'A1 ONE Unlimited 3XL', price: 35.99 },
    { channel: 'A1', planName: 'A1 ONE Unlimited', price: 34.99 },
    { channel: 'Telenor', planName: 'Тотал+ 36,99', price: 36.99 },
    { channel: 'Telenor', planName: 'Тотал Mакс 36.99', price: 36.99 },
  ],
  [
    { channel: 'Vivacom', planName: 'Unlimited 150', price: 43.99 },
    { channel: 'A1', planName: 'A1 ONE Unlimited 4XL', price: 39.99 },
    { channel: 'Telenor', planName: 'Тотал Mакс 44.99', price: 44.99 },
  ],
  [
    { channel: 'Vivacom', planName: 'Unlimited 150', price: 43.99 },
    { channel: 'A1', planName: 'A1 ONE Unlimited 5XL', price: 52.99 },
    { channel: 'Telenor', planName: 'Тотал+ 52,99', price: 52.99 },
    { channel: 'Telenor', planName: 'Тотал+ 69,99', price: 69.99 },
    { channel: 'Telenor', planName: 'Тотал Mакс 54.99', price: 54.99 },
  ],
];

export type PlanGroupping = typeof initialPlanGroupping;
