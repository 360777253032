import { Button, Grid } from '@material-ui/core';

import React from 'react';
import { PageID } from '../SideMenu';

const ChartPage: React.FunctionComponent<{ chart: string; chart2: string; name?: PageID; extendChart?: boolean }> = (
  props,
) => {
  let size = props.chart2 ? 70 : 100;
  if (props.extendChart) {
    size *= 1.2;
  }
  return (
    <>
      <Grid justify="space-between" direction="column" container>
        <Grid item style={{ width: '100%', height: 'calc(' + size + 'vh - 200px)' }}>
          <iframe
            frameBorder="0"
            width="100%"
            style={{ position: 'relative', top: 0, left: 0, height: '100%' }}
            src={'https://vivacom.indexed.company' + props.chart}
          ></iframe>
        </Grid>
        <br />
        {props.chart2 && (
          <Grid item style={{ width: '100%', height: 'calc(100vh)' }}>
            <iframe
              frameBorder="0"
              width="100%"
              style={{ position: 'relative', top: 0, left: 0, height: '100%' }}
              src={'https://vivacom.indexed.company' + props.chart2}
            ></iframe>
          </Grid>
        )}
        {(props.name === 'planIdentityMapping' ||
          props.name === 'deviceIdentityMapping' ||
          props.name === 'planGroupAndPrices') && (
          <Grid container>
            <Grid item style={{ marginTop: '-20px' }}>
              <Button variant="outlined" disabled onClick={() => null}>
                Edit mapping
              </Button>
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default ChartPage;
